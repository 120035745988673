import React, { useEffect, useState, useRef } from "react";
import { Box, Dialog, IconButton, TextField } from "@mui/material";
import fetchAddress from './fetchAddress.js'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
// import { MarkerClusterer } from '@googlemaps/markerclusterer';

/* const locations = [
    { key: 'operaHouse', location: { lat: -33.8567844, lng: 151.213108 } },
    { key: 'tarongaZoo', location: { lat: -33.8472767, lng: 151.2188164 } },
    { key: 'manlyBeach', location: { lat: -33.8209738, lng: 151.2563253 } },
    { key: 'hyderPark', location: { lat: -33.8690081, lng: 151.2052393 } },
    { key: 'theRocks', location: { lat: -33.8587568, lng: 151.2058246 } },
    { key: 'circularQuay', location: { lat: -33.858761, lng: 151.2055688 } },
    { key: 'harbourBridge', location: { lat: -33.852228, lng: 151.2038374 } },
    { key: 'kingsCross', location: { lat: -33.8737375, lng: 151.222569 } },
    { key: 'botanicGardens', location: { lat: -33.864167, lng: 151.216387 } },
    { key: 'museumOfSydney', location: { lat: -33.8636005, lng: 151.2092542 } },
    { key: 'maritimeMuseum', location: { lat: -33.869395, lng: 151.198648 } },
    { key: 'kingStreetWharf', location: { lat: -33.8665445, lng: 151.1989808 } },
    { key: 'aquarium', location: { lat: -33.869627, lng: 151.202146 } },
    { key: 'darlingHarbour', location: { lat: -33.87488, lng: 151.1987113 } },
    { key: 'barangaroo', location: { lat: - 33.8605523, lng: 151.1972205 } },
];


const PoiMarkers = (props) => {
    const map = useMap();
    const [markers, setMarkers] = useState({});
    const clusterer = useRef(null);
    useEffect(() => {
        if (!map) return;
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({ map });
        }
    }, [map]);
    useEffect(() => {
        clusterer.current?.clearMarkers();
        clusterer.current?.addMarkers(Object.values(markers));
    }, [markers]);
    const setMarkerRef = (marker, key) => {
        if (marker && markers[key]) return;
        if (!marker && !markers[key]) return;

        setMarkers(prev => {
            if (marker) {
                return { ...prev, [key]: marker };
            } else {
                const newMarkers = { ...prev };
                delete newMarkers[key];
                return newMarkers;
            }
        });
    };
    return (
        <>
            {props.pois.map((poi) => (
                <AdvancedMarker
                    key={poi.key}
                    position={poi.location}
                    ref={marker => setMarkerRef(marker, poi.key)}
                >
                    <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
                </AdvancedMarker>
            ))}
        </>
    );
}; */


function Component(props) {
    const [MapLocation, setMapLocation] = useState(props.LocationBase || {});
    const [MapCenter, setMapCenter] = useState(props.LocationBase || {});
    const [Address, setAddress] = useState((props.LocationBase && props.LocationBase.address) ? props.LocationBase.address : '');
    const [Phone, setPhone] = useState((props.LocationBase && props.LocationBase.phone) ? props.LocationBase.phone : '');
    const [Modified, setModified] = useState(false);
    const LastLocation = useRef()
    useEffect(() => {
        if (!props.Open && (MapCenter.lat || MapCenter.lng)) {
            setMapCenter({});
            setMapLocation({});
        }
        if (props.Open && (!MapCenter || !MapCenter.lat || !MapCenter.lng) && !!props.LocationBase && !!props.LocationBase.lat && !!props.LocationBase.lng) {
            setMapCenter(props.LocationBase);
            setMapLocation(props.LocationBase);
            setAddress(props.LocationBase.address);
            setPhone(props.LocationBase.phone);
        }
    }, [MapCenter, props.LocationBase, props.Open])

    const AddressObj2Str = raw => {
        if (!raw) return
        raw.house_road = raw.road ? (raw.road + (raw.house_number ? (' ' + raw.house_number) : '')) : '';
        let clean = [], index = ['house_road', 'suburb', 'municipality', 'state'];
        for (let i of index)
            if (raw[i])
                clean.push(raw[i])
        setAddress(clean.join(', '));
    }

    const timedFetchAddress = loc => {
        if (props.LocationBase && props.LocationBase.lat === loc.lat && props.LocationBase.lng === loc.lng && !!props.LocationBase.address)
            return;
        LastLocation.current = new Date();
        setTimeout(() => {
            if (new Date() - LastLocation.current > 2000) {
                fetchAddress(MapLocation).then(AddressObj2Str);
            }
        }, 2500)
    }

    useEffect(() => {
        setModified(
            (props.LocationBase.address || '') !== (Address || '')
            || (props.LocationBase.phone || '') !== (Phone || '')
            || (props.LocationBase.lat || '') !== (MapLocation.lat || '')
            || (props.LocationBase.lng || '') !== (MapLocation.lng || '')
        )
    }, [MapLocation, Address, props.LocationBase, Phone])

    return (
        <Dialog
            open={props.Open}
            onClose={() => props.Show(false)}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    p: 2,
                    width: 'min(600px, 90vw)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            width: 1,
                            gap:2,
                        }}>
                        <TextField
                            label='Dirección'
                            multiline
                            rows={4}
                            sx={{
                            }}
                            value={Address}
                            onChange={e => setAddress(e.target.value)}
                        />
                        <TextField
                            label='Teléfono'
                            type="number"
                            sx={{
                                '& input': {
                                    appearance: 'textfield',
                                    py:2,
                                },
                                '& input::-webkit-inner-spin-button,& input::-webkit-outer-spin-button': {
                                    appearance: 'none',
                                    display: 'none'
                                }
                            }}
                            value={Phone || ''}
                            onChange={e => setPhone(e.target.value)}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 2,
                        }}
                    >
                        {(Modified) ? (
                            <IconButton
                                onClick={() => props.setLocation(props.LocationPickingIndex, {
                                    address: Address || '',
                                    phone: Phone || '',
                                    lat: MapLocation.lat || '',
                                    lng: MapLocation.lng || '',
                                })}
                            >
                                <CheckCircleIcon />
                            </IconButton>
                        ) : ''}
                        <IconButton
                            onClick={() => props.Show(false)}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Box>
                </Box>
                {(MapCenter.lat && MapCenter.lng) ? (
                    <Box
                        sx={{
                            width: '100 %',
                            height: '50vh'
                        }}
                    >
                        <Map
                            className="map"
                            defaultZoom={13}
                            mapId='LocationPickMap'
                            center={MapCenter}
                            onCameraChanged={(ev) => setMapCenter(ev.detail.center)}
                            onClick={(ev) => {
                                setMapLocation(ev.detail.latLng);
                                if (ev.detail.latLng)
                                    timedFetchAddress(ev.detail.latLng)
                            }}
                        >
                            <AdvancedMarker
                                position={MapLocation}
                            >
                                <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
                            </AdvancedMarker>
                        </Map>
                    </Box>
                ) : ''}
            </Box>
        </Dialog >
    )
}

export default Component