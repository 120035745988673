import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import TextStyles from './TextStyles.js';
import { ref, set } from "firebase/database";
import { rtdb } from "./firebase.js";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TimeEdit from './TimeEdit-mod.js'

const EditMenuItemComp = (props) => {
    const {
        PlaceCategories,
        ProductCategories,
        MenuTitle,
        setMenuTitle,
        MenuSize,
        setMenuCategory,
        MenuCategory,
        MenuSelection,
        EditMenuItem,
        setMenuSize,
    } = props;

    return <Box
        key={MenuSelection + 'MenuItemMod' + EditMenuItem.toString()}
        sx={{
            py: 2,
            px: 1,
            borderRadius: 2,
            border: 'solid currentColor 1px',
            borderLeftWidth: 0,
            borderRightWidth: 0,
            my: 1
        }}
    >
        <Box
            sx={{
                display: 'flex',
                mb: 2,
                gap: 1,
            }}
        >
            <FormControl fullWidth>
                <InputLabel>Categoría</InputLabel>
                <Select
                    onChange={e => setMenuCategory(e.target.value)}
                    value={MenuCategory}
                    label='Categoría'
                >
                    <ListSubheader>Listado de Categorías</ListSubheader>
                    <MenuItem value={'place|Categorias'}> Lugares</MenuItem>
                    <MenuItem value={'product|Categorias'}> Productos</MenuItem>
                    <MenuItem value={'badge|Categorias'}> Etiquetas</MenuItem>
                    <MenuItem value={'all|Categorias'}> Todos</MenuItem>
                    <ListSubheader>Local</ListSubheader>
                    <MenuItem value={'place|All'}>Todos</MenuItem>
                    {Object.keys(PlaceCategories).map(cat => {
                        return cat !== 'default' ? <MenuItem
                            value={'place|' + cat}
                            key={'MenuItem-place-' + cat}
                        >
                            {cat}
                        </MenuItem> : ''
                    })}
                    <ListSubheader>Producto</ListSubheader>
                    <MenuItem value={'product|All'}>Todos</MenuItem>
                    {Object.keys(ProductCategories).map(cat => {
                        return cat !== 'default' ? <MenuItem
                            value={'product|' + cat}
                            key={'MenuItem-product-' + cat}
                        >
                            {cat}
                        </MenuItem> : ''
                    })}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel>Tamaño</InputLabel>
                <Select
                    label='Tamaño'
                    onChange={e => setMenuSize(e.target.value)}
                    value={MenuSize}
                >
                    {['Destacado', 'Grande', 'Mediano', 'Pequeño'].map(opt => {
                        return <MenuItem
                            value={opt}
                            key={'SizeSelect-' + opt}
                        >
                            {opt}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box>
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <TextField
                label='Título'
                sx={{
                    flexGrow: 1
                }}
                value={MenuTitle || ''}
                onChange={e => setMenuTitle(e.target.value)}
            />
            <IconButton
                aria-label="add"
                color="info"
                disabled={!MenuCategory}
                onClick={() => {
                    set(ref(rtdb, 'public/Categories/HomeMenu/' + MenuSelection + '/menu/' + EditMenuItem.toString()), {
                        'title': MenuTitle || '',
                        'size': MenuSize,
                        'source': MenuCategory.split('|')[0],
                        'category': MenuCategory.split('|')[1] || 'All',
                    })
                    setMenuTitle('');
                    setMenuSize('Mediano');
                    setMenuCategory('');
                }}
            >
                <CheckCircleIcon />
            </IconButton>
        </Box>
    </Box>
};

function Component(props) {

    const { PlaceCategories, ProductCategories, HomeMenu } = props;
    const [MenuTitle, setMenuTitle] = useState('');
    const [MenuSize, setMenuSize] = useState('Mediano');
    const [MenuCategory, setMenuCategory] = useState('');
    const [MenuSelection, setMenuSelection] = useState('default');
    const [SaveMenuAs, setSaveMenuAs] = useState('');
    const [SaveMenuAsPriority, setSaveMenuAsPriority] = useState(0);
    const [EditMenuItem, setEditMenuItem] = useState(-1);
    const [ConfirmationDialog, setConfirmationDialog] = useState(null);
    const [RmIndex, setRmIndex] = useState(null);
    const [MenuAvailabilityHours, setMenuAvailabilityHours] = useState([]);
    const [ForceMenu, setForceMenu] = useState(false);

    useEffect(() => {
        if (!!HomeMenu && !!HomeMenu[MenuSelection]) {
            setSaveMenuAs(MenuSelection);
            setSaveMenuAsPriority(HomeMenu[MenuSelection].priority || 0);
            setMenuAvailabilityHours(HomeMenu[MenuSelection].availability || []);
            setEditMenuItem(-1)
        }
    }, [MenuSelection, HomeMenu])

    useEffect(() => {
        let activeItem = HomeMenu[MenuSelection].menu[EditMenuItem] || {};
        setMenuTitle(activeItem.title || '');
        setMenuSize(activeItem.size || 'Mediano');
        setMenuCategory((!!activeItem.source && activeItem.category) ? [activeItem.source, activeItem.category].join('|') : '')
    }, [EditMenuItem, HomeMenu, MenuSelection])

    const moveMenuItem = (from, to) => {
        let tempMenu = [...HomeMenu[MenuSelection].menu];
        tempMenu.splice(to, 0, tempMenu.splice(from, 1)[0]);
        set(ref(rtdb, 'public/Categories/HomeMenu/' + MenuSelection + '/menu'), tempMenu)
    }

    useEffect(() => {
        forceUserMenu();
    }, [ForceMenu, MenuSelection])

    const forceUserMenu = () => {
        set(ref(rtdb, 'Users/' + props.User.uid + '/menu'), ForceMenu ? { title: MenuSelection, ts: new Date().getTime() } : null);
    }

    return <Box>
        <Box
            sx={{
                ...TextStyles.h2,
                textAlign: 'center',
            }}
        >
            Menú de la aplicación
        </Box>
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                mb: 2,
            }}
        >
            <FormControlLabel control={<Checkbox defaultChecked={ForceMenu} onChange={(e) => setForceMenu(e.target.checked)} />} label="Forzar" />
            <FormControl fullWidth>
                <InputLabel>Menú</InputLabel>
                <Select
                    onChange={e => setMenuSelection(e.target.value)}
                    value={MenuSelection}
                    label='Menú'
                >
                    {Object.keys(HomeMenu).map(menu => {
                        return menu !== 'default' ? <MenuItem
                            value={menu}
                            key={'HomeMenu-name-' + menu}
                        >
                            {menu}
                        </MenuItem> : ''
                    })}
                    <MenuItem value={'default'}>Por Defecto</MenuItem>
                </Select>
            </FormControl>
            <IconButton
                aria-label="delete"
                color="info"
                disabled={MenuSelection === 'default'}
                onClick={() => {
                    setConfirmationDialog({
                        title: 'Confirma la eliminación del menú',
                        content: MenuSelection,
                        fn: () => {
                            set(ref(rtdb, "public/Categories/HomeMenu/" + MenuSelection), null);
                            forceUserMenu();
                            setMenuSelection('default');
                        }
                    })
                }}
            >
                <DeleteIcon />
            </IconButton>
        </Box>
        <Box
            sx={{
                border: 'solid 1px currentColor',
                borderTopWidth: 0,
                borderRadius: 2.5,
                p: 2,
                mt: 2,
            }}
        >
            {(!!HomeMenu[MenuSelection] && !!HomeMenu[MenuSelection].menu) ? HomeMenu[MenuSelection].menu.map((MenuItem, index) => {
                if (EditMenuItem !== index)
                    return <Box
                        key={MenuSelection + 'MenuItem' + index.toString()}
                        sx={{
                            display: 'flex',
                            justifyContent: 'stretch',
                            gap: 1,
                            px: 2,
                            my: 1,
                            alignItems: 'center',
                            '&:not(:hover) .menuMove': {
                                display: 'none',
                            }
                        }}
                    >
                        <Box
                            className='menuMove'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                m: -1,
                                mr: 0,
                            }}
                        >
                            <IconButton
                                aria-label="move up"
                                size="small"
                                onClick={() => moveMenuItem(index, index - 1)}
                                disabled={index === 0}
                            >
                                <ArrowDropUpIcon
                                    fontSize="inherit"
                                />
                            </IconButton>
                            <IconButton
                                aria-label="move down"
                                size="small"
                                onClick={() => moveMenuItem(index, index + 1)}
                                disabled={index === (HomeMenu[MenuSelection].menu.length - 1)}
                            >
                                <ArrowDropDownIcon
                                    fontSize="inherit"
                                />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: 1,
                                flexGrow: 1,
                            }}
                            onClick={() => setEditMenuItem(index)}
                        >
                            <Box>
                                {(MenuItem.source === 'place' ? 'Lugares'
                                    : MenuItem.source === 'product' ? 'Productos'
                                        : MenuItem.source === 'badge' ? 'Etiquetas'
                                            : 'Todos'
                                ) + ': '}
                                {MenuItem.category === 'All' ? 'Todos' : (MenuItem.category || '')}
                            </Box>
                            <Box>
                                {MenuItem.size || ''}
                            </Box>
                            <Box>
                                {MenuItem.title || ''}
                            </Box>
                        </Box>
                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                setRmIndex(index);
                                setConfirmationDialog({
                                    title: 'Confirma la eliminación de la sección',
                                    content: (MenuItem.title || 'Sin Título') + ' - ' + (MenuItem.source === 'place' ? 'Lugares' : 'Productos') + ': ' + (MenuItem.category === 'All' ? 'Todos' : (MenuItem.category || '')),
                                    fn: () => {
                                        let tempMenu = [...HomeMenu[MenuSelection].menu];
                                        if (RmIndex != null) {
                                            tempMenu.splice(RmIndex, 1);
                                            set(ref(rtdb, 'public/Categories/HomeMenu/' + MenuSelection + '/menu'), tempMenu)
                                            forceUserMenu();
                                        }
                                    }
                                })
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>;
                else
                    return <EditMenuItemComp
                        PlaceCategories={PlaceCategories}
                        ProductCategories={ProductCategories}
                        MenuTitle={MenuTitle}
                        setMenuTitle={setMenuTitle}
                        MenuSize={MenuSize}
                        setMenuCategory={setMenuCategory}
                        MenuCategory={MenuCategory}
                        MenuSelection={MenuSelection}
                        EditMenuItem={EditMenuItem}
                        setMenuSize={setMenuSize}
                    />
            }) : ''}
            {(EditMenuItem >= 0 && EditMenuItem === (HomeMenu[MenuSelection].menu.length || 0)) ? <EditMenuItemComp
                PlaceCategories={PlaceCategories}
                ProductCategories={ProductCategories}
                MenuTitle={MenuTitle}
                setMenuTitle={setMenuTitle}
                MenuSize={MenuSize}
                setMenuCategory={setMenuCategory}
                MenuCategory={MenuCategory}
                MenuSelection={MenuSelection}
                EditMenuItem={EditMenuItem}
                setMenuSize={setMenuSize}
            /> : ''}
            <Button
                variant="outlined"
                endIcon={<AddCircleIcon />}
                onClick={() => setEditMenuItem(HomeMenu[MenuSelection].menu.length || 0)}
            >
                Sumar Item
            </Button>
        </Box>
        <Box
            sx={{
                border: 'solid 1px currentColor',
                borderTopWidth: 0,
                borderRadius: 2.5,
                p: 2,
                mt: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    mb: 3,
                }}
            >
                <TextField
                    label='Prioridad'
                    type="number"
                    InputProps={{ inputProps: { min: -1, max: 10 } }}
                    sx={{ width: 70 }}
                    disabled={SaveMenuAs === 'default'}
                    value={SaveMenuAs === 'default' ? 0 : (SaveMenuAsPriority || 0)}
                    onChange={e => setSaveMenuAsPriority(e.target.value)}
                />
                <TextField
                    label='Grabar como'
                    sx={{
                        flexGrow: 2
                    }}
                    value={SaveMenuAs || ''}
                    onChange={e => setSaveMenuAs(e.target.value)}
                />
                <IconButton
                    aria-label="save"
                    color="info"
                    onClick={() => {
                        set(ref(rtdb, "public/Categories/HomeMenu/" + SaveMenuAs), {
                            menu: [...HomeMenu[MenuSelection].menu],
                            priority: SaveMenuAs === 'default' ? 0 : (SaveMenuAsPriority || 0),
                            availability: MenuAvailabilityHours,
                        });
                        forceUserMenu();
                        setMenuSelection(SaveMenuAs);
                    }}
                >
                    <CheckCircleIcon />
                </IconButton>
            </Box>
            <TimeEdit
                PlaceWorkingHours={MenuAvailabilityHours}
                setPlaceWorkingHours={setMenuAvailabilityHours}
                Admin={MenuSelection !== 'default'}
                DarkMode={props.DarkMode}
                PlaceWorkingHoursModified={null}
            />
        </Box>
        <Dialog
            onClose={() => setConfirmationDialog(null)}
            open={ConfirmationDialog !== null}
        >
            <DialogTitle id="simple-dialog-title">{ConfirmationDialog !== null ? ConfirmationDialog.title : ''}</DialogTitle>
            <DialogContent>
                {ConfirmationDialog !== null ? ConfirmationDialog.content : ''}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setConfirmationDialog(null)}
                    color="primary"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        if (ConfirmationDialog !== null)
                            ConfirmationDialog.fn();
                        setConfirmationDialog(null);
                    }}
                    color="primary"
                >
                    Borrar
                </Button>
            </DialogActions>
        </Dialog>

    </Box>
}

export default Component