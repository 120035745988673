import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import TextStyles from './TextStyles.js'
import { ref, set } from "firebase/database";
import { rtdb } from "./firebase.js";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import picStorageUpload from "./picUpload.js";
import picUrl from './picUrl.js';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

function Component(props) {
    const [NewBadgeName, setNewBadgeName] = useState('');
    const [NewBadgePromoted, setNewBadgePromoted] = useState(false);
    const [NewBadgePicture, setNewBadgePicture] = useState();
    const [NewBadgePromoPic, setNewBadgePromoPic] = useState();
    const [DisabledButton, setDisabledButton] = useState(true);
    const [badgesList, setbadgesList] = useState([]);
    const [ConfirmationDialogOpen, setConfirmationDialogOpen] = useState(null);
    const [DragOver, setDragOver] = useState(false);

    useEffect(() => {
        setDisabledButton(!NewBadgeName || !NewBadgePicture);
    }, [NewBadgeName, NewBadgePicture])

    /*     useEffect(() => {
            setNewBadgeName('');
            setNewBadgePicture('\uf49d');
        }, []) */

    useEffect(() => {
        let pre = Object.keys(props.Badges).toSorted() || [];
        setbadgesList(pre);
    }, [props.Badges])

    const elemCount = (name) => {
        let count = 0;
        for (let p in props.List)
            if (props.List[p].badge === name) count++;
        return count
    }

    const picUpload = (files, fn, transparency) => {
        picStorageUpload(transparency, files, fn, Object.keys(props.Pictures))
    }

    const PictureDrop = (ev, fn, transparency) => {
        ev.preventDefault();
        setDragOver(false);
        if (ev.dataTransfer.items) {
            let pics = [];
            for (let item of [...ev.dataTransfer.items])
                if (item.kind === "file")
                    pics.push(item.getAsFile());
            picUpload(pics, fn, transparency);
        } else
            picUpload(ev.dataTransfer.files, fn, transparency);
    }

    return <Box>
        <Box
            sx={{
                ...TextStyles.h2,
                textAlign: 'center',
            }}
        >
            Etiquetas
        </Box>
        <Box
            sx={{
                margin: 'auto',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                gap: 1
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    alignItems: 'center',
                    userSelect: 'none',
                }}
            >
                <Box
                    component="fieldset"
                    sx={{
                        border: '1px solid ' + (props.DarkMode ? '#ffffff3b' : '#0000003b'),
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: '3em',
                        height: '4em',
                        marginTop: '-0.5em',
                        borderWidth: 2,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: NewBadgePicture ? ('url(' + picUrl(props.Pictures, NewBadgePicture, true) + ')') : 'none',
                        cursor: 'pointer',
                    }}
                    onClick={e => document.getElementById('badgeInput').click()}
                    onDragOver={ev => {
                        ev.preventDefault();
                        setDragOver(true);
                    }}
                    onDragLeave={ev => {
                        ev.preventDefault();
                        setDragOver(false);
                    }}
                    onDrop={e => PictureDrop(e, setNewBadgePicture, true)}
                >
                    <input
                        id="badgeInput"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={e => picUpload(e.target.files, setNewBadgePicture, true)}
                        style={{ display: 'none' }}
                    />
                    {!NewBadgePicture ? (
                        <AddIcon
                            sx={{
                                fontSize: 20,
                                opacity: DragOver ? 1 : .5,
                            }}
                        />
                    ) : ''}
                </Box>


                <TextField
                    label='Etiqueta'
                    sx={{
                        flexGrow: 1,
                        '& input': {
                            py: 1.5,
                            px: 2,
                        }
                    }}
                    value={NewBadgeName}
                    onChange={e => setNewBadgeName(e.target.value)}
                />


                <Box
                    component="fieldset"
                    sx={{
                        border: '1px solid ' + (props.DarkMode ? '#ffffff3b' : '#0000003b'),
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: '5em',
                        height: '3em',
                        borderWidth: 2,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: NewBadgePromoPic ? ('url(' + picUrl(props.Pictures, NewBadgePromoPic, false) + ')') : 'none',
                        cursor: 'pointer',
                    }}
                    onClick={e => document.getElementById('badgePromoInput').click()}
                    onDragOver={ev => {
                        ev.preventDefault();
                        setDragOver(true);
                    }}
                    onDragLeave={ev => {
                        ev.preventDefault();
                        setDragOver(false);
                    }}
                    onDrop={e => PictureDrop(e, setNewBadgePromoPic, false)}
                >
                    <input
                        id="badgePromoInput"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={e => picUpload(e.target.files, setNewBadgePromoPic, false)}
                        style={{ display: 'none' }}
                    />
                    {!NewBadgePicture ? (
                        <AddIcon
                            sx={{
                                fontSize: 20,
                                opacity: DragOver ? 1 : .5,
                            }}
                        />
                    ) : ''}
                </Box>

                {NewBadgePromoted
                    ? (<StarIcon sx={{ fontSize: 25 }} onClick={()=>setNewBadgePromoted(false)} />)
                    : (<StarBorderIcon sx={{ fontSize: 25 }} onClick={()=>setNewBadgePromoted(true)}/>)
                }


                <IconButton
                    aria-label="add"
                    color="info"
                    disabled={DisabledButton}
                    onClick={() => {
                        set(ref(rtdb, "public/Categories/PromoBadge/" + NewBadgeName), {
                            badge: NewBadgePicture || null,
                            promoted: NewBadgePromoted || false,
                            promoPic: NewBadgePromoPic || null,
                        })
                        setNewBadgeName('');
                        setNewBadgePicture('');
                        setNewBadgePromoted(false);
                        setNewBadgePromoPic('');
                    }}
                >
                    <CheckCircleIcon />
                </IconButton>
            </Box>
            {badgesList.map((name, i) => {
                let badge = props.Badges[name].badge,
                    count = elemCount(name),
                    promoted = props.Badges[name].promoted || false,
                    promoPic = props.Badges[name].promoPic || '',
                    setEdit = () => {
                        setNewBadgeName(name);
                        setNewBadgePicture(badge);
                        setNewBadgePromoPic(promoPic);
                        setNewBadgePromoted(promoted);
                    }

                return <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        alignItems: 'center',
                        userSelect: 'none',
                        pl: 0,
                        '& button': {
                            transition: '.3s',
                            opacity: 0,
                        },
                        '&:hover button': {
                            opacity: 1,
                        }
                    }}
                    key={'list-Badges-' + name}
                >
                    <Box
                        sx={{
                            height: '3em',
                            width: '3em',
                            backgroundImage: 'url(' + picUrl(props.Pictures, badge, true) + ')',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                        }}
                        onClick={setEdit}
                    />
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                        onClick={setEdit}
                    >
                        {name + ' (' + count + ')'}
                    </Box>
                    <Box
                        sx={{
                            height: '3em',
                            width: '5em',
                            backgroundImage: 'url(' + picUrl(props.Pictures, promoPic, false) + ')',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                        onClick={setEdit}
                    />
                    {promoted
                        ? (<StarIcon sx={{ fontSize: 25 }} onClick={setEdit} />)
                        : (<StarBorderIcon sx={{ fontSize: 25, opacity: .5 }} onClick={setEdit} />)
                    }
                    <IconButton
                        aria-label="delete"
                        disabled={!!count}
                        color='error'
                        onClick={() => setConfirmationDialogOpen(name)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            })}
        </Box>
        <Dialog
            onClose={() => setConfirmationDialogOpen(null)}
            open={ConfirmationDialogOpen !== null}
        >
            <DialogTitle id="simple-dialog-title">Confirma la eliminación de la Etiqueta</DialogTitle>
            <DialogContent>
                {ConfirmationDialogOpen}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setConfirmationDialogOpen(null)}
                    color="primary"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        set(ref(rtdb, "public/Categories/PromoBadge/" + ConfirmationDialogOpen), null)
                        setConfirmationDialogOpen(null)
                    }}
                    color="primary"
                >
                    Borrar
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
}

export default Component